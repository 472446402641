import React, { useEffect, useState } from "react"
import { ComparisonSlider } from "react-comparison-slider"
import Logo from "../../assets/svg/logo.svg"
import Spinner from "../Spinner"
import { loadAllImages } from "../../helpers/preload-image"

const SlideCompareImage = ({ files, metaData }) => {
  const [imageCompare, setImageCompare] = useState({ left: "", right: "" })
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    files.sort((a, b) => (a.order > b.order) ? 1 : -1)

    loadAllImages(files.map(item => item.url)).then(() => {
      const [img] = files

      if (files.length === 1) {
        setImageCompare({ left: img.url, right: img.url })
      } else {
        setImageCompare({ left: img.url, right: files[1].url })
      }

      setLoading(false);
    }).catch(() => {
      alert("image load failed")
    })

  }, [files])

  const changeFilesCompare = (key, value) => {
    setImageCompare({ ...imageCompare, [key]: value })
  }

  if (files.length === 0) return <Spinner/>

  return (
    <div className={"slide-compare-image"}>
      <div className={"container"}>
        <header className={"header"}>
          <a href="/" target="_blank">
            <img src={Logo} alt="" />
          </a>
        </header>
        <div className={"column"}>
          <div className={"left-block"}>

            { loading ? <Spinner/> : null}
            <ComparisonSlider
              defaultValue={50}
              handleAfter={<div className="handle-after"></div>}
              handleBefore={<div className="handle-before"></div>}
              handle={({ isFocused }) => {
                return (
                  <div className="handle"></div>
                )
              }}
              itemOne={
                <div
                  className="left-block__image"
                  style={{ backgroundImage: `url(${imageCompare.left})` }}
                ></div>
              }
              itemTwo={
                <div
                  className="left-block__image"
                  style={{ backgroundImage: `url(${imageCompare.right})` }}
                ></div>
              }
              aspectRatio={16 / 9}
              orientation="horizontal"
            />
          </div>
          <div className={"right-block"}>
            <h4>Compare Conditions</h4>
            <ul className={"check-box-block"}>
              <li>L</li>
              <li>R</li>
              <li>Name</li>

              {files.map((item) => {
                return (
                  <React.Fragment key={item.lastModified}>
                    <li key={item.lastModified + "left"}>
                      <input
                        name="left"
                        type="radio"
                        value={item.url}
                        checked={item.url === imageCompare.left}
                        onChange={(e) => {
                          changeFilesCompare("left", e.target.value)
                        }}
                      />
                    </li>
                    <li key={item.lastModified + "right"}>
                      <input
                        name="right"
                        type="radio"
                        value={item.url}
                        checked={item.url === imageCompare.right}
                        onChange={(e) => {
                          changeFilesCompare("right", e.target.value)
                        }}
                      />
                    </li>
                    <li key={item.lastModified + "name"}>{(item?.label) ? item?.label : item?.name}</li>
                  </React.Fragment>
                )
              })}
            </ul>
          </div>
        </div>
        <footer className={"footer"}>
          <div className={"label"}>{metaData.label || ""}</div>
          <div>{metaData.description || ""}</div>
        </footer>
      </div>
    </div>
  )
}

export default SlideCompareImage
