import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout"
import SlideCompareImage from "../../components/user-account/SlideCompareImage"
import {
  downloadAllImageUser,
  getMetaData,
} from "../../service/fire-base-image"
import firebase from "gatsby-plugin-firebase"

const Id = ({ id, navigate }) => {
  const [user, setUser] = useState(null)
  const [metaData, setMetaData] = useState(null)
  const [files, setFiles] = useState([])

  useEffect(() => {
    const getUserId = async (id) => {
      let user = await firebase.firestore().collection('comparisons').doc(id.toString()).get()
      setUser(user)
    }

    getUserId(id)
  }, [id])

  useEffect(() => {
    if (!user) return

    const userId = user.get('userid')
    const metaData = getMetaData(`${userId}/${id}/metadata`)
    const urls = downloadAllImageUser(`${userId}/${id}`)

    Promise.all([metaData, urls])
      .then((results) => {
        const [metaData, files] = results
        setMetaData(metaData?.customMetadata)
        setFiles(files)
      })
      .catch(() => {
        navigate("/404")
      })
  }, [user])

  return (
    <Layout
      pageSlug={"image-compare"}
      bodyClass={`
      page-template-default 
      page wp-embed-responsive 
      singular missing-post-thumbnail 
      has-no-pagination 
      not-showing-comments 
      footer-top-visible 
      customize-support`}
    >
      <SlideCompareImage files={files} metaData={metaData} />
    </Layout>
  )
}
export default Id
