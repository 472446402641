function loadAllImages(imageURLs) {
  let imagesOK = 0
  return new Promise((res, rej) => {
    for (let i = 0; i < imageURLs.length; i++) {
      const img = new Image()
      img.src = imageURLs[imagesOK]

      img.onload = function () {
        imagesOK++
        if (imagesOK >= imageURLs.length) {
          res()
        }
      }

      img.onerror = function () {
        rej();
      }
    }
  })
}

export { loadAllImages }
